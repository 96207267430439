import React from 'react'
import PropTypes from 'prop-types'
import Container from '@parkholidays/legacy_components/dist/container'
import Typography from '@parkholidays/legacy_components/dist/typography'
import Card, { CardContent } from '@parkholidays/legacy_components/dist/card'
import Button from '@parkholidays/legacy_components/dist/button'
import withCheckoutLayout from '../../layouts/withCheckoutLayout'
import CheckoutHeader from '../../components/CheckoutHeader'
import CardHeader from './components/CardHeader'
import CardHeaderImage from './components/CardHeaderImage'
import WifiVoucherCode from './components/WifiVoucherCode'
import MobilePhoneNumber from './components/MobilePhoneNumber'
import CardActions from './components/CardActions'
import {datadogLogger} from '../../datadogLogger/logger'

const propTypes = {
  voucher: PropTypes.string.isRequired,
  parkCode: PropTypes.string.isRequired,
  selectedProduct: PropTypes.shape({
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }),
}

const successMessage =
  `Your code has been sent by SMS. If you haven't received your
code by text, please check your mobile number and try again.`

const failureMessage =
  `Something went wrong on our end. Please remain
on this page & try again later.`

const Confirmation = ({ voucher, parkCode, selectedProduct: { duration } }) => {
  const voucherInputRef = React.createRef()
  const [mobilePhoneNumber, setMobilePhoneNumber] = React.useState('')
  const [sentCodeByMobile, setSentCodeByMobile] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false);

  const copyToClipboard = (e) => {
    e.preventDefault()
    voucherInputRef.current.select()
    document.execCommand('copy')
  }

  const returnToReferrerUri = (e) => {
    e.preventDefault()
    window.location.href = `https://ph.vfastpark.co.uk/${parkCode.toLowerCase()}?voucher=${voucher}`
  }

  const sendCodeBySMS = async () => {
    setIsLoading(true)
    try {
      datadogLogger.info(`env ${JSON.stringify(process.env)}`)
      const lambdaFunctionURL = process.env?.SMS_LAMBDA_FUNCTION_URL || 'https://zqnsf8imx4.execute-api.eu-west-2.amazonaws.com/staging/service'
      datadogLogger.info(`Send SMS lambdaFunctionURL - ${lambdaFunctionURL}`)
      const response = await fetch(lambdaFunctionURL,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            mobilePhoneNumber: mobilePhoneNumber,
            voucher: voucher,
          }),
        }
      );
      if (response.ok) {
        // The request was successful
        setSentCodeByMobile(true);
        datadogLogger.info(`Send SMS worked ok - ${response.text()}`)
      } else {
        const errorText = await response.text();
        setSentCodeByMobile(false);
        console.error(`Failed to send: ${response.statusText || errorText}`);
      }
    } catch (err) {
      setSentCodeByMobile(false);
      datadogLogger.error(`Send SMS error was - ${JSON.stringify(err)}`)
    } finally {
      // Always set loading to false at the end of the process
      setIsLoading(false);
    }
  };


  return (
    <>
      <CheckoutHeader>
        <Container>
          <Typography as="h1" noMargins>
            Your voucher code
          </Typography>
          <Typography as="p" noMargins>
            Congratulations – you’re almost there. Just copy your voucher code
            and click &apos;Get online&apos;, this will take you to the V-FAST
            portal where you can enter your unique code, valid on up to 6
            devices.
          </Typography>
        </Container>
      </CheckoutHeader>
      <Container>
        <Card color="white" elevation={3} $style={{ marginBottom: '16px' }}>
          <CardHeader>
            <Typography
              as="h2"
              color="white"
              noMargins
              $style={{ float: 'left' }}
            >
              {`Your ${duration}-day`}
              <br />
              wifi voucher code
            </Typography>
            <CardHeaderImage
              /*eslint max-len: ["error", { "ignoreUrls": true }]*/
              src={`https://park-holidays.s3.eu-west-2.amazonaws.com/assets/checkout/illustrations-${duration}day.png`}
              alt="Duration Image"
            />
          </CardHeader>
          <CardContent>
            <WifiVoucherCode
              value={voucher}
              ref={voucherInputRef}
              readOnly
              tabIndex="0"
            />
            <CardActions>
              <Button
                variant="outlined"
                role="button"
                tabIndex="0"
                onClick={(e) => copyToClipboard(e)}
              >
                Copy to Clipboard
              </Button>
              <Button
                color="touring"
                role="link"
                tabIndex="0"
                onClick={(e) => returnToReferrerUri(e)}
              >
                Get Online
              </Button>
            </CardActions>
          </CardContent>
        </Card>
        <Card color="white" elevation={3} $style={{ marginBottom: '16px' }}>
          <CardHeader $style={{ backgroundColor: '#ececec' }}>
            <Typography as="h2" noMargins>
              Text your code to your mobile
            </Typography>
          </CardHeader>
          <CardContent>
            {sentCodeByMobile === true ? (
              (<Typography as="p" color="corporate">
                {successMessage}
              </Typography>)
            ) : sentCodeByMobile !== false ? null :
              (<Typography as="p" color="corporate">
                {failureMessage}
              </Typography>)}
            <MobilePhoneNumber
              type="tel"
              // eslint-disable-next-line max-len
              pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
              value={mobilePhoneNumber}
              onChange={(e) => setMobilePhoneNumber(e.target.value)}
              tabIndex="0"
              placeholder="e.g. 07700123456"
            />
            <CardActions>
              <Button
                color="corporate"
                role="link"
                tabIndex="0"
                onClick={(e) => sendCodeBySMS()}
              >
                {isLoading ? "Sending" : "Send Code"}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
        <Typography as="p" color="corporate" noMargins>
          <Typography as="strong" $style={{ display: 'block' }}>
            Terms and Conditions
          </Typography>
          By using our internet service, you hereby expressly acknowledge and
          agree that there are significant security, privacy and confidentiality
          risks inherent in accessing or transmitting information through the
          internet, whether the connection is facilitated through wired or
          wireless technology.
        </Typography>
      </Container>
    </>
  )
}

Confirmation.propTypes = propTypes

export default withCheckoutLayout(Confirmation)
